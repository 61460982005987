import type { IndustryOptionsObject } from "../../translation-keys/industry-option-keys.js";

const FormIndustryOptions: IndustryOptionsObject = {
  "Active Pharmaceutical Ingredients": "Principes pharmaceutiques actifs",
  "Aerospace": "Aéronautique",
  "Animal Health": "Santé animale",
  "Automotive": "Automobile",
  "Banking/Finance/Insurance": "Banque/finance/assurance",
  "Biotechnology - Med Device": "Biotechnologie - Dispositif médical",
  "Biotechnology - Pharma": "Biotechnologie - Pharmaceutique",
  "Blood & Biologics": "Sang et produits biologiques",
  "Chemicals": "Produits chimiques",
  "Clinical Research": "Recherche clinique",
  "Combination Device": "Dispositif médical combiné",
  "Combination Pharma": "Combinaison de médicaments",
  "Communications": "Communications",
  "Computer Equipment/Peripherals": "Équipements/périphériques informatiques",
  "Construction/Architecture/Engineering": "Construction/architecture/ingénierie",
  "Consulting Services": "Services de consultation",
  "Contract Manufacturing - Med Device": "Sous-traitance - Dispositif médical",
  "Contract Manufacturing - Pharma": "Sous-traitance - Pharmaceutique",
  "Contract Research Organization (CRO)": "Organismes de recherche sous contrat (CRO)",
  "Cosmetics": "Produits cosmétiques",
  "Diagnostics - Medical": "Diagnostics - Médical",
  "Dietary Supplements": "Compléments alimentaires",
  "Distribution": "Distribution",
  "Education": "Formation",
  "Electronics": "Électronique",
  "Energy": "Énergie",
  "Environmental": "Environnement",
  "Food": "Alimentation",
  "General Manufacturing": "Industrie manufacturière générale",
  "Government": "Administration",
  "Healthcare": "Santé",
  "Hospital": "Hôpital",
  "Laboratory - Biologics": "Laboratoire - Produits biologiques",
  "Laboratory - ISO": "Laboratoire - ISO",
  "Laboratory - MD": "Laboratoire - Dispositif médical",
  "Laboratory - Other": "Laboratoire - Autre",
  "Lawyer / Law Firm": "Avocat/cabinet d'avocats",
  "Life Science Supplier": "Fournisseur de produits du secteur des sciences de la vie",
  "Media": "Médias",
  "Medical Device": "Dispositif médical",
  "Medical Marijuana": "Medical Marijuana",
  "Nuclear": "Nucléaire",
  "Oil & Gas / Mining": "Hydrocarbures/industrie minière",
  "Packaging": "Conditionnement",
  "Pharmaceutical": "Pharmaceutique",
  "Publishing": "Publication",
  "Retail": "Vente au détail",
  "Software/Hardware Supplier": "Fournisseur logiciel/matériel",
  "Student/University": "Université",
  "Technology": "Technologie",
  "Transportation/Logistics": "Transport/logistique",
  "Travel/Hospitality/Restaurant": "Tourisme/hôtellerie/restauration"
};

export default FormIndustryOptions;